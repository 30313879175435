.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .dataGrid-card {
    flex-direction: row;
    padding:15px;
    text-align: center;
  }
  
  .dataGrid-title{
    font-family: sans-serif !important;
    font-size: 35px !important;
    font-weight: bold !important;
    border-bottom: 2px rgb(199, 198, 198) solid !important;
  }
  .dataGrid-edit-btn-padded{
    background-color: rgb(76, 175, 80);
    color:white !important;
    border-radius: 0.5em !important;
    text-align: center !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding:15px !important;
  }
  
  .dataGrid-edit-btn{
    text-align: center !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .dataGrid-view-btn-padded{
    background-color: rgb(55, 110, 228) !important;
    color:white !important;
    border-radius: 0.5em !important;
    text-align: center !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    padding:15px !important;
  }
  
  .dataGrid-view-btn{
    border-radius: 0.5em !important;
    text-align: center !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  
  .dx-datagrid-headers .dx-header-row {  
    font-weight: bold !important;
    background-color: #ececec !important;
  };
  .dx-header-filter-indicator, .dx-cell-focus-disabled, .dx-datagrid-content {
    font-size: 12px !important;
  };
  .dx-state-hover
  {
    background-color: #cfcfcf !important;
  }
  .control-button{
    
    color: #337ab7 !important;
    text-align:center !important;
    transition: all 0.2s !important;
  };
  
  a.button4:hover{
    border-color: rgba(255,255,255,1) !important;
  };
  
  @media all and (max-width:30em){
    a.button4 {
        display:block;
        margin:0.2em auto;
    };
  };
  
  .red-next-last-service-background {
    background-color: red !important;
  }
  
  .yellow-next-last-service-background {
    background-color: yellow !important;
  }
  
  .SOCT-App-header {
    background-color: #282c34;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .SOCT-App {
    text-align: start;
  }
  .SOCT-App-link {
    color: #61dafb !important;
  }
  .SOCT-App-link:hover {
    color: white !important;
    background-color: #90949ba1 !important;
  }
  .reject-btn {
    background-color: #bf0000 !important;
    color: white !important;
    padding: 5px !important;
    font-size: 14px; 
    border-radius: 4px !important;
  }
  .initiate-btn {
    background-color: rgb(146, 218, 150) !important;
    color: white !important;
    padding: 5px !important;
    font-size: 14px; 
    border-radius: 4px !important;
  }
  .complete-btn {
    background-color: rgb(236, 176, 65) !important;
    color: white !important;
    padding: 5px !important;
    font-size: 14px; 
    border-radius: 4px !important;
  }
  .cancel-btn {
    background-color: rgb(205, 92, 92) !important;
    color: white !important;
    padding: 5px !important;
    font-size: 14px; 
    border-radius: 4px !important;
  }
  .disabled-btn {
    background-color: rgb(175, 175, 175) !important;
    color: white !important;
    padding: 5px !important;
    font-size: 14px; 
    border-radius: 4px !important;
  }
  .dx-state-hover
    {
    background-color:#CFCFCF !important; 
    } 
    .custom-move-btn{
      color :'white';
      font-weight:bold !important;
      background-color: rgb(235, 216, 49) !important;
    }